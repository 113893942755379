import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import store from './store'
import VueRouter from 'vue-router'
import router from './router'
import 'animate.css';
import ElementUI from 'element-ui';

Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(ElementUI);

new Vue({
  el: '#app',
  render: h => h(App),
  store: store,
  router: router,
  beforeCreate () {
    Vue.prototype.$bus = this;
  }
})

<template>
  <div id='window-logbox'>

    <div id='login' v-show='!isRegister'>
      <div id='login-username'>
        <el-input v-model="login_username" placeholder="请输入账户名" maxlength='16' size='mini' clearable
          @input='loginInputChanged("username")'></el-input>
      </div>
      <div id='login-password'>
        <el-input v-model="login_password" placeholder="请输入密码" maxlength='16' show-password size='mini'
          @input='loginInputChanged("password")'></el-input>
      </div>
      <div id='login-button'>
        <el-button type="success" round size='mini' :disabled='!login_button' @click='login()'>登录</el-button>
      </div>
      <div id='login-register' @click='modeRegister'>
        <a>没有账号? 点击注册</a>
      </div>
      <div id='login-guest'>
        <a @click='loginGuest'>暂不登录? 使用游客模式</a>
      </div>
    </div>

    <div id='register' v-show='isRegister'>
      <div id='register-id'>
        <span> 账户ID </span>
        <el-input v-model="register_id" size='mini' disabled></el-input>
      </div>
      <div id='register-username'>
        <el-input v-model="register_username" placeholder="请输入账户名" maxlength='16' size='mini' clearable
          @input='registerInputChanged("username")'></el-input>
      </div>
      <div id='register-password'>
        <el-input v-model="register_password" placeholder="请输入密码" maxlength='16' show-password size='mini'
          @input='registerInputChanged("password")'></el-input><br>
        <el-input v-model="register_confirmPassword" placeholder="请再次输入密码" maxlength='16' show-password size='mini'
          @input='registerInputChanged("confirmPassword")'>
        </el-input>
      </div>
      <div id='register-button'>
        <el-button type="success" round size='mini' :disabled='!register_button' @click='registerPreCheck()'>注册
        </el-button>
      </div>
      <div id='register-login'>
        <a @click='modeLogin'>已有账号? 前往登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('1234567890', 19);
const cookieGenerator = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 25);
import axios from 'axios';
import Cookies from 'js-cookie'
import md5 from 'js-md5'
import * as server from '../../server'
export default {
  name: 'window-logbox',
  data () {
    return {
      isRegister: false,
      login_username: '',
      login_password: '',
      login_button: false,
      register_id: '',
      register_username: '',
      register_password: '',
      register_confirmPassword: '',
      register_button: false
    }
  },
  methods: {
    showNotification (title, message, type, duration, position = 'top-right') {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration,
        position: position,
      });
    },
    modeRegister () {
      this.register_id = nanoid();
      this.isRegister = true;
    },
    modeLogin () {
      this.isRegister = false;
    },
    loginGuest () {
      this.$confirm('在游客模式下, 数据将保存在当前浏览器中, 无法跨浏览器、跨设备使用，且在清空浏览器数据时会丢失。<br> 您可以之后再注册/登录账户以使用云同步功能。<br><br> 是否继续?', '提示', {
        confirmButtonText: '确定使用游客模式',
        cancelButtonText: '取消使用游客模式',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.showNotification('警告', '当前处于游客模式, 无法使用在线功能', 'warning', 0, 'bottom-right');
        this.$bus.$emit('closeLogin');

      }).catch(() => {

      });
    },
    loginInputChanged (type) {
      let reg = /^\w+$/;
      if (type == 'username') {
        if (!reg.test(this.login_username.charAt(this.login_username.length - 1))) {
          this.login_username = this.login_username.slice(0, -1);
          this.showNotification('注意', '账户名只能由数字、字母以及下划线组成', 'warning', 2000);
        }
      }
      else if (type == 'password') {
        if (!reg.test(this.login_password.charAt(this.login_password.length - 1))) {
          this.login_password = this.login_password.slice(0, -1);
          this.showNotification('注意', '密码只能由数字、字母以及下划线组成', 'warning', 2000);
        }
      }
      if (this.login_username.length > 0 && this.login_password.length > 0) {
        this.login_button = true;
      }
      else {
        this.login_button = false;
      }
    },
    registerInputChanged (type) {
      let reg = /^\w+$/;
      if (type == 'username') {
        if (!reg.test(this.register_username.charAt(this.register_username.length - 1))) {
          this.register_username = this.register_username.slice(0, -1);
          this.showNotification('注意', '账户名只能由数字、字母以及下划线组成', 'warning', 2000);
        }
      }
      else if (type == 'password') {
        if (!reg.test(this.register_password.charAt(this.register_password.length - 1))) {
          this.register_password = this.register_password.slice(0, -1);
          this.showNotification('注意', '密码只能由数字、字母以及下划线组成', 'warning', 2000);
        }
      }
      else if (type == 'confirmPassword') {
        if (!reg.test(this.register_confirmPassword.charAt(this.register_confirmPassword.length - 1))) {
          this.register_confirmPassword = this.register_confirmPassword.slice(0, -1);
          this.showNotification('注意', '密码只能由数字、字母以及下划线组成', 'warning', 2000);
        }
      }
      if (this.register_username.length > 0 && this.register_password.length > 0 && this.register_confirmPassword.length > 0) {
        this.register_button = true;
      }
      else {
        this.register_button = false;
      }
    },
    registerPreCheck () {
      if (this.register_username.length < 6) {
        this.showNotification('注意', '用户名需大于6位字符', 'warning', 3000);
        return;
      }
      if (this.register_password.length < 8) {
        this.showNotification('注意', '请使用至少8位的密码', 'warning', 3000);
        return;
      }
      else if (this.register_password != this.register_confirmPassword) {
        this.showNotification('错误', '两次输入密码不一致', 'error', 3000);
      }
      else {
        this.register();
      }
    },
    login () {
      const loadingInstance = this.$loading({
        lock: true,
        text: '登录中'
      });
      let newCookie = cookieGenerator();
      axios({
        method: 'post',
        url: server.default.loginPath,
        data: new URLSearchParams({
          'username': this.login_username,
          'password': md5(this.login_password),
          'cookie': newCookie
        })
      }).then((response) => {
        loadingInstance.close();
        if (response.status != 200) {
          this.$alert('无法连接到服务器, 请稍后重试', '消息');
        }
        else {
          if (response.data == 'ERR_LOGIN_WRONG') {
            this.$alert('账户不存在, 或账户密码不匹配', '消息');
          }
          else if (response.data.status == 'SUC_LOGIN') {
            this.showNotification('登录成功', '欢迎回来 ' + this.login_username, 'success', 3000);
            this.$bus.$emit('closeLogin');
            this.$bus.$emit('showUsername', this.login_username);
            Cookies.set('loginCookie', newCookie, { expires: 7 });
          }
        }
      })
    },
    register () {
      const loadingInstance = this.$loading({
        lock: true,
        text: '登录中'
      });
      axios({
        method: 'post',
        url: server.default.registerPath,
        data: new URLSearchParams({
          'id': this.register_id,
          'username': this.register_username,
          'password': md5(this.register_password)
        })
      }).then((response) => {
        loadingInstance.close();
        if (response.status != 200) {
          this.$alert('无法连接到服务器, 请稍后重试', '消息');
        }
        else {
          if (response.data == 'ERR_REGISTER_USERNAME') {
            this.$alert('该用户名已被占用', '消息');
          }
          else if (response.data == 'SUC_REGISTER') {
            this.$alert('注册成功!', '消息');
            this.modeLogin();
          }
        }
      })
    }
  },
}
</script>

<style scoped>
#window-logbox {
  text-align: center;
}
#window-logbox .el-input {
  font-size: 10px;
  width: 200px;
  margin: 0 0 5px 0;
}
#window-logbox a {
  font-size: 10px;
  cursor: pointer;
}
#window-logbox a:hover {
  text-decoration: underline;
  color: rgb(51, 184, 245);
}
#window-logbox #register-id span {
  font-size: 10px;
  margin: 0 5px 0 0;
}
#window-logbox #register-id .el-input {
  width: 160px;
}
</style>
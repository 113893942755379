<template>
  <div id='window-banner'>
    <i id='i-menu' class='el-icon-more-outline' @click='openMenu'></i>
    <span id='right-float'>
      <span id='username'>欢迎您 {{username}}</span>
      <span id='currentTime'></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'window-banner',
  data () {
    return {
      username: '游客'
    }
  },
  methods: {
    openMenu () {
      this.$bus.$emit('openMenu');
    },
    showUsername (username) {
      this.username = username;
    }
  },
  mounted () {
    this.$bus.$on('showUsername', username => this.showUsername(username));
    let currentTime = document.getElementById('currentTime');
    let currentTimeText;
    setInterval(() => {
      let currentClock = new Date();
      currentTimeText = currentClock.getFullYear() + '/'
      currentTimeText += (currentClock.getMonth() + 1 < 10 ? '0' + (currentClock.getMonth() + 1) : currentClock.getMonth() + 1) + '/';
      currentTimeText += (currentClock.getDate() < 10 ? '0' + currentClock.getDate() : currentClock.getDate()) + '/ ';
      currentTimeText += (currentClock.getHours() < 10 ? '0' + currentClock.getHours() : currentClock.getHours()) + ':';
      currentTimeText += (currentClock.getMinutes() < 10 ? '0' + currentClock.getMinutes() : currentClock.getMinutes()) + ':';
      currentTimeText += (currentClock.getSeconds() < 10 ? '0' + currentClock.getSeconds() : currentClock.getSeconds()) + '';
      currentTime.innerHTML = currentTimeText;
    }, 1000);
  },
  beforeDestroy () {
    this.$bus.$off('showUsername');
  },
}
</script>

<style scoped>
#window-banner {
  background-color: rgb(196, 193, 196);
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
}
#window-banner #i-menu {
  margin: 0 auto 0 5px;
  cursor: pointer;
}
#window-banner #right-float {
  float: right;
  font-size: 10px;
  margin: 0 10px 0 0;
}
#window-banner #username {
  margin: 0 10px 0 0;
}
</style>
<template>
  <div id='page-index'>
    <i id='i-pic' class='el-icon-notebook-1'></i><br>
    <p class='p-title'> TiaDos - Tia多功能记事本 </p>
    <p class='p-subtitle'> 使用左侧菜单选择页面</p>
  </div>
</template>

<script>
export default {
  name: 'page-index',
  data () {
    return {

    }
  }
}
</script>

<style scoped>
#page-index {
  text-align: center;
  padding: 8% 0 0 0;
}
#page-index #i-pic {
  font-size: 100px;
}
#page-index .p-title {
  font-size: 30px;
}
#page-index .p-subtitle {
  font-size: 20px;
}
</style>
let mainServer = 'http://49.4.0.68:9000'
// let mainServer = 'http://localhost:9000';

let servers = {
  pingPath: mainServer + '/ping',
  registerPath: mainServer + '/register',
  loginPath: mainServer + '/login',
  autoLoginPath: mainServer + '/autoLogin',
  updatePath: mainServer + '/lastUpdate',
  uploadPath: mainServer + '/upload',
  downloadPath: mainServer + '/download',
  changePasswordPath: mainServer + '/changePassword'
}

export default servers;
<template>
  <div id='page-counter'>
    <div id='div-list'>
      <div id='div-list-manage'>
        <el-tooltip class="item" effect="light" content="选中所有项目" placement="bottom-start">
          <div id='div-list-manage-select' @click='taskListSelectAll'>
            <i class='el-icon-turn-off'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="将选中的项目永久删除" placement="bottom-start">
          <div id='div-list-manage-remove' @click='taskListRemoveSelected'>
            <i class='el-icon-delete'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="新建一个项目" placement="bottom-start">
          <div id='div-list-manage-new' @click='taskListNew'>
            <i class='el-icon-circle-plus-outline'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="刷新项目列表" placement="bottom-start">
          <div id='div-list-manage-refresh' @click='taskListRefresh'>
            <i class='el-icon-refresh'></i>
          </div>
        </el-tooltip>
      </div>

      <div id='div-list-main'>
        <div id='div-list-main-exist' v-show='!isDataEmpty'>
          <div id='div-list-main-exist-data'>
            <div id='div-list-main-exist-data-list'>
              <div class='div-list-main-exist-data-item' v-for="(item,index) in data_counter_all" :key='index'>
                <input type='checkbox' class='div-list-main-exist-data-item-checkbox' @change='taskListSelectEach'>
                <span @click='showItem(index)'> {{item.counter_title}} </span>
                <span class='div-list-main-exist-data-item-count'> {{item.counter_count}} </span>
              </div>
            </div>
          </div>
        </div>

        <div id='div-list-main-empty' v-show='isDataEmpty'>
          <i class='el-icon-check'></i><br>
          <div> 没有数据 </div>
        </div>

      </div>

      <div id='div-list-cover' v-show='editingIndex>=0' @click='closeItem()'></div>
    </div>

    <div id='div-mainArea'>
      <div id='div-mainArea-editing' v-show='editingIndex>=0'>
        <div id='div-mainArea-editing-back'>
          <el-tooltip class="item" effect="light" content="不保存并关闭编辑" placement="right-end">
            <i class='el-icon-back' @click='closeItem'></i>
          </el-tooltip>
        </div>
        <div id='div-mainArea-editing-title'>
          <el-input type="text" placeholder="请输入内容" maxlength='8' show-word-limit
            v-model="data_counter_current.counter_title" @change="isUnsaved = true" @input="inputLimit('title')">
          </el-input>
        </div>
        <div id='div-mainArea-editing-description'>
          <el-input type="textarea" rows='10' placeholder="请输入内容" maxlength='1000' show-word-limit
            v-model="data_counter_current.counter_description" resize='none' @change="isUnsaved = true"
            @input="inputLimit('description')"></el-input>
        </div>
        <div id='div-mainArea-editing-count'>
          <span>任务计次</span>
          <el-input-number v-model="data_counter_current.counter_count" :min="0" :max="1000" size="mini"
            @change="isUnsaved = true">
          </el-input-number>
        </div>
        <div id='div-mainArea-editing-options'>
          <el-button type="success" icon="el-icon-check" round size='mini' @click='saveItem'>保存项目</el-button>
          <el-button type="danger" icon="el-icon-delete" round size='mini' @click='deleteItem'>删除项目</el-button>
        </div>
      </div>

      <div id='div-mainArea-empty' v-show='!editingIndex>=0'>
        <i class='el-icon-cold-drink'></i><br>
        <span> 选择或创建一个项目来编辑 </span>
      </div>
    </div>
  </div>
</template>

<script>
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('ABCDEFGHIJ', 20);
export default {
  name: 'page-counter',
  data () {
    return {
      data_counter_all: [],
      data_counter_current: { counter_id: '', counter_title: '', counter_description: '', counter_count: 0 },
      isDataEmpty: false,
      isEditing: false,
      isCreating: false,
      isUnsaved: false,
      isListSelectAll: false,
      editingIndex: -1,
      key: 0
    }
  },
  methods: {
    showNotification (title, message, type, duration) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration
      });
    },
    taskListSelectAll () {
      if (this.isDataEmpty) {
        this.showNotification('操作失败', '当前列表没有待选中项目', 'error', 3000);
      }
      else {
        let elements = document.getElementsByClassName('div-list-main-exist-data-item-checkbox');
        for (let element of elements) {
          element.checked = !this.isTaskSelectedAll;
        }
        this.isTaskSelectedAll = !this.isTaskSelectedAll;
        if (this.isTaskSelectedAll) {
          document.getElementsByClassName('el-icon-turn-off')[0].className = 'el-icon-open';
        }
        else {
          document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
        }
      }
    },
    taskListSelectEach () {
      let elements = document.getElementsByClassName('div-list-main-exist-data-item-checkbox');
      for (let index in elements) {
        if (!elements[index].checked) {
          break;
        }
        if (index == elements.length - 1) {
          if (document.getElementsByClassName('el-icon-turn-off').length > 0) {
            document.getElementsByClassName('el-icon-turn-off')[0].className = 'el-icon-open';
          }
          return;
        }
      }
      if (document.getElementsByClassName('el-icon-open').length > 0) {
        document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
      }
    },
    taskListRemoveSelected () {
      let elements = document.getElementsByClassName('div-list-main-exist-data-item-checkbox');
      if ([...elements].filter(element => element.checked).length == 0) {
        this.showNotification('操作失败', '未选中任何项目', 'error', 3000);
      }
      else {
        this.$confirm('此操作将永久删除项目, 是否继续?', '提示', {
          confirmButtonText: '确定删除',
          cancelButtonText: '取消删除',
          type: 'warning'
        }).then(() => {
          for (let index = elements.length - 1; index >= 0; index--) {
            if (elements[index].checked) {
              this.data_counter_all.splice(index, 1);
            }
          }
          for (let element of elements) {
            element.checked = false;
          }
          this.showNotification('操作成功', '已将选中的项目删除', 'success', 3000);
          this.taskListRefreshSilent();
          if (this.isTaskSelectedAll) {
            document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
          }
        }).catch(() => {
        });
      }
    },
    taskListNew () {
      this.isCreating = true;
      this.showItem(this.data_counter_all.length);
    },
    taskListRefreshSilent () {
      this.writeToStorage()
      this.readFromStorage();
      if (this.data_counter_all.length > 0) {
        this.isDataEmpty = false;
        this.key++;
      }
      else {
        this.isDataEmpty = true;
      }
    },
    taskListRefresh () {
      this.taskListRefreshSilent();
      this.showNotification('操作成功', '已刷新任务列表', 'success', 3000);
    },
    showItem (index) {
      if (!this.isCreating) {
        this.data_counter_current.counter_id = this.data_counter_all[index].counter_id;
        this.data_counter_current.counter_title = this.data_counter_all[index].counter_title;
        this.data_counter_current.counter_description = this.data_counter_all[index].counter_description;
        this.data_counter_current.counter_count = this.data_counter_all[index].counter_count;
      }
      this.editingIndex = index;
    },
    closeItem () {
      if (this.isUnsaved) {
        this.$confirm('自上次保存后的未保存修改将会丢失, 是否继续? <br> 点击关闭以取消返回', '提示', {
          confirmButtonText: '保存并返回',
          cancelButtonText: '不保存并返回',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          distinguishCancelAndClose: true,
        }).then(() => {
          this.saveItem();
          this.isCreating = false;
          this.isUnsaved = false;
          this.editingIndex = -1;
          this.data_counter_current = { counter_id: '', counter_title: '', counter_description: '', counter_count: 0 };
        }).catch((action) => {
          if (action == 'cancel') {
            this.isCreating = false;
            this.isUnsaved = false;
            this.editingIndex = -1;
            this.data_counter_current = { counter_id: '', counter_title: '', counter_description: '', counter_count: 0 };
          }
        });
      }
      else {
        this.isCreating = false;
        this.editingIndex = -1;
        this.data_counter_current = { counter_id: '', counter_title: '', counter_description: '', counter_count: 0 };
      }
    },
    saveItem () {
      if (this.data_counter_current.counter_title.length <= 0) {
        this.showNotification('操作失败', '请至少输入任务标题', 'error', 3000);
      }
      else {
        if (this.isCreating) {
          this.data_counter_current.counter_id = nanoid();
          this.data_counter_all.push(this.data_counter_current);
        }
        else {
          for (let index = 0; index < this.data_counter_all.length; index++) {
            if (this.data_counter_all[index].counter_id == this.data_counter_current.counter_id) {
              this.data_counter_all[index] = this.data_counter_current;
              break;
            }
          }
        }
        this.showNotification('操作成功', '保存成功', 'success', 3000);
        this.isCreating = false;
        this.isUnsaved = false;
        this.taskListRefreshSilent();
      }
    },
    deleteItem () {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(() => {
        this.showNotification('操作成功', '已删除项目', 'success', 3000);
        this.data_counter_all.splice(this.editingIndex, 1);
        this.editingIndex = -1;
        this.isUnsaved = false;
        this.data_counter_current = { counter_id: '', counter_title: '', counter_description: '', counter_count: 0 };
        this.taskListRefreshSilent();
      }).catch(() => {
      });
    },
    inputLimit (place) {
      if (place === 'title') {
        if (this.data_counter_current.counter_title.length >= 8) {
          this.showNotification('注意', '标题文本不能超过8个字符', 'warning', 1000);
        }
      }
      else if (place === 'description') {
        if (this.data_counter_current.counter_description.length >= 1000) {
          this.showNotification('注意', '正文文本不能超过1000个字符', 'warning', 1000);
        }
      }
    },
    writeToStorage () {
      localStorage.setItem('data_counter_all', JSON.stringify(this.data_counter_all));
      let lastUpdateClock = new Date();
      let lastUpdate = lastUpdateClock.getFullYear().toString();
      lastUpdate += (lastUpdateClock.getMonth() + 1) < 10 ? '0' + (lastUpdateClock.getMonth() + 1) : lastUpdateClock.getMonth() + 1;
      lastUpdate += lastUpdateClock.getDate() < 10 ? '0' + lastUpdateClock.getDate() : lastUpdateClock.getDate();
      lastUpdate += lastUpdateClock.getHours() < 10 ? '0' + lastUpdateClock.getHours().toString() : lastUpdateClock.getHours()
      lastUpdate += lastUpdateClock.getMinutes() < 10 ? '0' + lastUpdateClock.getMinutes() : lastUpdateClock.getMinutes();
      lastUpdate += lastUpdateClock.getSeconds() < 10 ? '0' + lastUpdateClock.getSeconds() : lastUpdateClock.getSeconds();
      localStorage.setItem('time_last_updated', lastUpdate);
    },
    readFromStorage () {
      if (localStorage.getItem('data_counter_all') && localStorage.getItem('data_counter_all').length > 2) {
        this.data_counter_all = JSON.parse(localStorage.getItem('data_counter_all'));
        this.isDataEmpty = false;
      }
      else {
        this.data_counter_list = [];
        this.isDataEmpty = true;
      }
    }
  },
  mounted () {
    this.readFromStorage();
  },
}
</script>

<style scoped>
#page-counter #div-list {
  position: absolute;
  background-color: rgb(62, 249, 205);
  border-right: 1px solid darkorange;
  width: 150px;
  height: 100%;
}
#page-counter #div-list-manage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0 20px 0;
  text-align: center;
}
#page-counter #div-list-manage div {
  cursor: pointer;
}
#page-counter #div-list-main-empty {
  text-align: center;
}
#page-counter #div-list-main-empty i {
  margin: 50px 0 0 0;
  font-size: 100px;
}
#page-counter #div-list-main-empty div {
  font-size: 15px;
}
#page-counter #div-list-main-exist-data-list {
  margin: 10px 10px 0 10px;
}
#page-counter .div-list-main-exist-data-item {
  margin: 5px 0 5px 0;
}
#page-counter .div-list-main-exist-data-item-checkbox {
  position: relative;
  top: 3px;
}
#page-counter .div-list-main-exist-data-item span {
  font-size: 10px;
  cursor: pointer;
}
#page-counter .div-list-main-exist-data-item-count {
  position: relative;
  top: 6.5px;
  float: right;
  color: rgb(250, 51, 184);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
#page-counter .div-list-main-exist-data-item i {
  position: relative;
  top: 5px;
  float: right;
}
#page-counter #div-list-cover {
  position: fixed;
  left: 0;
  top: 30px;
  height: 100%;
  width: 150px;
  z-index: 10;
  cursor: pointer;
  background-color: rgba(189, 185, 185, 0.626);
}
#page-counter #div-mainArea {
  margin: 0 0 0 200px;
}
#page-counter #div-mainArea-empty {
  text-align: center;
}
#page-counter #div-mainArea-empty i {
  margin: 100px 0 30px 0;
  font-size: 100px;
}
#page-counter #div-mainArea-editing {
  font-size: 15px;
}
#page-counter #div-mainArea-editing-back {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
#page-counter #div-mainArea-editing-title {
  margin: 5px 0 5px 0;
}
#page-counter #div-mainArea-editing-description {
  margin: 0 0 5px 0;
}
#page-counter #div-mainArea-editing-count {
  margin: 10px 0 5px 0;
}
#page-counter #div-mainArea-editing-count span {
  margin: 0 15px 0 0;
}
#page-counter #div-mainArea-editing-options {
  margin: 20px 0 0 0;
  text-align: center;
}
#page-counter #div-mainArea-editing-options .el-button {
  margin: 0 50px 0 0;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.config.productionTip = false
Vue.use(Vuex)

const actions = {

}

const mutations = {

}

const state = {

}

const getters = {

}

export default new Vuex.Store({
  actions: actions,
  mutations: mutations,
  state: state,
  getters: getters
})
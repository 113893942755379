import Vue from 'vue';
import VueRouter from 'vue-router'

import PageIndex from '../components/MainPage/PageIndex.vue'
import PageTask from '../components/MainPage/PageTask/PageTask.vue'
import PageCounter from '../components/MainPage/PageCounter/PageCounter.vue'

Vue.use(VueRouter);
let originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}

const router = new VueRouter({
  routes: [
    {
      name: 'PageIndex',
      path: '/index',
      component: PageIndex,
      meta: {
        isAuth: false,
      }
    },
    {
      name: 'PageTask',
      path: '/task',
      component: PageTask,
      meta: {
        isAuth: false,
      }
    },
    {
      name: 'PageCounter',
      path: '/counter',
      component: PageCounter,
      meta: {
        isAuth: false,
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    next();
  }
  else {
    next();
  }
})
router.afterEach(() => {

})

export default router;
<template>
  <div id='window-menu'>
    <div id='div-title'>
      <span> 菜单 </span>
      <i class='el-icon-close' @click='closeMenu'></i>
      <div style="height:30px"></div>
    </div>

    <div id='div-hotbar'>
      <div id='div-hotbar-user' class='div-hotbar-items' @click='changePassword'>
        <i id='i-hotbar-user' class='el-icon-user'></i><br>
        <span> 修改密码</span>
      </div>

      <div id='div-hotbar-logout' class='div-hotbar-items' @click='logout'>
        <i id='i-hotbar-logout' class='el-icon-minus'></i><br>
        <span> 退出登录 </span>
      </div>

      <div id='div-hotbar-upload' class='div-hotbar-items' @click='upload'>
        <i id='i-hotbar-upload' class='el-icon-upload2'></i><br>
        <span> 云端上载 </span>
      </div>

      <div id='div-hotbar-download' class='div-hotbar-items' @click='download'>
        <i id='i-hotbar-download' class='el-icon-download'></i><br>
        <span> 云端下载 </span>
      </div>
    </div>

    <div id='div-list'>
      <el-button class='button-list' type="primary" @click='boardSwitch("router_board_task")'> 常规任务板
      </el-button>
      <el-button class='button-list' type="primary" @click='boardSwitch("router_board_counter")'> 计次任务板
      </el-button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios';
import md5 from 'js-md5'
import * as server from '../../server.js';
export default {
  name: 'window-menu',
  data () {
    return {
    }
  },
  methods: {
    showNotification (title, message, type, duration, position = 'top-right') {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration,
        position: position,
      });
    },
    closeMenu () {
      this.$bus.$emit('closeMenu');
    },
    boardSwitch (to) {
      this.$bus.$emit(to);
      this.closeMenu();
    },
    changePassword () {
      if (Cookies.get('loginCookie') == null) {
        return;
      }
      this.$prompt('将密码修改为', '修改密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\w{8,16}$/,
        inputErrorMessage: '密码只能使用8到16位的数字、字母、下划线'
      }).then(({ value }) => {
        console.log(value);
        axios({
          method: 'post',
          url: server.default.changePasswordPath,
          data: new URLSearchParams({
            'cookie': Cookies.get('loginCookie'),
            'password': md5(value)
          })
        }).then((response) => {
          if (response.status != 200) {
            this.$alert('无法连接到服务器, 请稍后重试', '消息');
          }
          else {
            if (response.data == 'done') {
              this.logout();
              this.showNotification('成功', '密码已修改,请重新登录', 'success', 3000);
            }
          }
        })
      }).catch(() => {
      });
    },
    logout () {
      Cookies.remove('loginCookie');
      location.reload();
    },
    upload () {
      if (Cookies.get('loginCookie') == null) {
        return;
      }
      if ((localStorage.getItem('data_task_all') && localStorage.getItem('data_task_all').length > 2) || (localStorage.getItem('data_counter_all') && localStorage.getItem('data_counter_all').length > 2)) {
        axios({
          method: 'post',
          url: server.default.updatePath,
          data: new URLSearchParams({
            'cookie': Cookies.get('loginCookie')
          })
        }).then((response) => {
          if (response.status != 200) {
            this.$alert('无法连接到服务器, 请稍后重试', '消息');
          }
          else {
            let localLastUpdateArray = localStorage.getItem('time_last_updated').split("");
            localLastUpdateArray.splice(4, 0, '-');
            localLastUpdateArray.splice(7, 0, '-');
            localLastUpdateArray.splice(10, 0, ' ');
            localLastUpdateArray.splice(13, 0, ':');
            localLastUpdateArray.splice(16, 0, ":");
            let localLastUpdate = localLastUpdateArray.join("");
            if (response.data != 'never') {
              this.$confirm('检测到云端已存在内容, 是否使用本地数据覆盖云端数据?<br><br>本地数据最后一次更新时间: ' + localLastUpdate + '<br>云端数据最后一次更新时间: ' + response.data, '提示', {
                confirmButtonText: '确定覆盖',
                cancelButtonText: '取消覆盖',
                type: 'warning',
                dangerouslyUseHTMLString: true
              }).then(() => {
                axios({
                  method: 'post',
                  url: server.default.uploadPath,
                  data: new URLSearchParams({
                    'cookie': Cookies.get('loginCookie'),
                    'lastUpdate': localLastUpdate,
                    'dataTask': localStorage.getItem('data_task_all'),
                    'dataCounter': localStorage.getItem('data_counter_all')
                  })
                }).then((response) => {
                  if (response.status != 200) {
                    this.$alert('无法连接到服务器, 请稍后重试', '消息');
                  }
                  else if (response.data == 'done') {
                    this.showNotification('成功', '数据已上传到云端', 'success', 3000)
                  }
                })
              }).catch(() => {
              });
            }
            else {
              axios({
                method: 'post',
                url: server.default.uploadPath,
                data: new URLSearchParams({
                  'cookie': Cookies.get('loginCookie'),
                  'lastUpdate': localLastUpdate,
                  'dataTask': localStorage.getItem('data_task_all'),
                  'dataCounter': localStorage.getItem('data_counter_all')
                })
              }).then((response) => {
                if (response.status != 200) {
                  this.$alert('无法连接到服务器, 请稍后重试', '消息');
                }
                else if (response.data == 'done') {
                  this.showNotification('成功', '数据已上传到云端', 'success', 3000)
                }
              })
            }
          }
        })
      }
      else {
        this.showNotification('错误', '当前待上传的数据为空', 'warning', 3000);
      }
    },
    download () {
      if (Cookies.get('loginCookie') == null) {
        return;
      }
      axios({
        method: 'post',
        url: server.default.updatePath,
        data: new URLSearchParams({
          'cookie': Cookies.get('loginCookie')
        })
      }).then((response) => {
        if (response.status != 200) {
          this.$alert('无法连接到服务器, 请稍后重试', '消息');
        }
        else {
          if (response != 'never') {
            if (localStorage.getItem('time_last_updated') != null) {
              let localLastUpdateArray = localStorage.getItem('time_last_updated').split("");
              localLastUpdateArray.splice(4, 0, '-');
              localLastUpdateArray.splice(7, 0, '-');
              localLastUpdateArray.splice(10, 0, ' ');
              localLastUpdateArray.splice(13, 0, ':');
              localLastUpdateArray.splice(16, 0, ":");
              let localLastUpdate = localLastUpdateArray.join("");
              this.$confirm('检测到本地已存在内容, 是否使用云端数据覆盖本地数据?<br><br>本地数据最后一次更新时间: ' + localLastUpdate + '<br>云端数据最后一次更新时间: ' + response.data, '提示', {
                confirmButtonText: '确定覆盖',
                cancelButtonText: '取消覆盖',
                type: 'warning',
                dangerouslyUseHTMLString: true
              }).then(() => {
                axios({
                  method: 'post',
                  url: server.default.downloadPath,
                  data: new URLSearchParams({
                    'cookie': Cookies.get('loginCookie')
                  })
                }).then((response) => {
                  if (response.status != 200) {
                    this.$alert('无法连接到服务器, 请稍后重试', '消息');
                  }
                  else {
                    let formatTime = response.data.lastUpdate.split("-").join("");
                    formatTime = formatTime.split(":").join("");
                    formatTime = formatTime.split(" ").join("");
                    localStorage.setItem('data_task_all', response.data.task);
                    localStorage.setItem('data_counter_all', response.data.counter);
                    localStorage.setItem('time_last_updated', formatTime);
                    this.$alert('数据已下载到本地, 请刷新页面', '消息', {
                      confirmButtonText: '立即刷新',
                      callback () {
                        location.reload();
                      }
                    });
                  }
                })
              }).catch(() => {
              })
            }
            else {
              axios({
                method: 'post',
                url: server.default.downloadPath,
                data: new URLSearchParams({
                  'cookie': Cookies.get('loginCookie')
                })
              }).then((response) => {
                if (response.status != 200) {
                  this.$alert('无法连接到服务器, 请稍后重试', '消息');
                }
                else {
                  let formatTime = response.data.lastUpdate.split("-").join("");
                  formatTime = formatTime.split(":").join("");
                  formatTime = formatTime.split(" ").join("");
                  localStorage.setItem('data_task_all', response.data.task);
                  localStorage.setItem('data_counter_all', response.data.counter);
                  localStorage.setItem('time_last_updated', formatTime);
                  this.showNotification('成功', '数据已下载到本地', 'success', 3000);
                  location.reload();
                }
              })
            }
          }
          else {
            this.showNotification('错误', '云端可下载的没有数据', 'warning', 3000);
          }
        }
      })
    }
  },
}
</script>

<style scoped>
#window-menu {
  background-color: rgb(85, 255, 184);
  border-right: 1px solid darkorange;
}
#div-title span {
  margin: 5px 0 0 60px;
  float: left;
}
#window-menu #div-title i {
  margin: 5px 5px 0 0;
  float: right;
  cursor: pointer;
}
#window-menu #div-hotbar {
  margin: 5px 0 0 5px;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
#window-menu #div-hotbar div {
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  flex-shrink: 0;
  margin: 0 0 10px 0;
}
#window-menu #div-list {
  margin: 10px 0 10px 0;
  text-align: center;
}
#window-menu #div-list .button-list {
  margin: 0 0 5px 0;
}
</style>
<template>
  <div id='page-task'>
    <div id='div-list'>
      <div id='div-list-manage'>
        <el-tooltip class="item" effect="light" content="选中本页所有项目" placement="bottom-start">
          <div id='div-list-manage-select' @click='taskListSelectAll'>
            <i class='el-icon-turn-off'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="将选中的项目标记为完成" placement="bottom-start">
          <div id='div-list-manage-check' @click='taskListCheckSelected'>
            <i class='el-icon-circle-check'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="将选中的项目永久删除" placement="bottom-start">
          <div id='div-list-manage-remove' @click='taskListRemoveSelected'>
            <i class='el-icon-delete'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="新建一个项目" placement="bottom-start">
          <div id='div-list-manage-new' @click='taskListNew'>
            <i class='el-icon-circle-plus-outline'></i>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="light" content="刷新项目列表" placement="bottom-start">
          <div id='div-list-manage-refresh' @click='taskListRefresh'>
            <i class='el-icon-refresh'></i>
          </div>
        </el-tooltip>
      </div>

      <div id='div-list-main'>
        <div id='div-list-main-sort'>
          <el-dropdown size='mini' :show-timeout="100" :hide-timeout="100" @command='changeSortType'>
            <div>
              <span class="el-dropdown-link">任务状态 {{sortType}}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown" divided>
              <el-dropdown-item icon="el-icon-info" command='all'>全部</el-dropdown-item>
              <el-dropdown-item icon="el-icon-question" command='panding'>进行中</el-dropdown-item>
              <el-dropdown-item icon="el-icon-success" command='done'>已完成</el-dropdown-item>
              <el-dropdown-item icon="el-icon-error" command='expired'>已超时</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div id='div-list-exist' v-show='!isDataEmpty'>
          <div id='div-list-exist-data'>
            <div id='div-list-exist-data-list'>
              <div class='div-list-exist-data-item' v-for="(item,index) in data_task_list" :key='index'>
                <input type='checkbox' class='div-list-exist-data-item-checkbox' @change='taskListSelectEach'>
                <span @click='showItem(index)'> {{item.task_title}} </span>
                <!-- <i class='el-icon-question'></i> -->
              </div>
            </div>
          </div>
        </div>

        <div id='div-list-empty' v-show='isDataEmpty'>
          <i class='el-icon-check'></i><br>
          <div> 当前分类下没有数据 </div>
        </div>
      </div>

      <div id='div-list-cover' v-show='editingIndex>=0' @click='closeItem()'></div>
    </div>

    <div id='div-mainArea'>
      <div id='div-mainArea-editing' v-show='editingIndex>=0'>
        <div id='div-mainArea-editing-top'>
          <span id='div-mainArea-editing-top-back'>
            <el-tooltip class="item" effect="light" content="不保存并关闭编辑" placement="right-end">
              <i class='el-icon-back' @click='closeItem'></i>
            </el-tooltip>
          </span>
          <span id='div-mainArea-editing-top-status'>
            <div>
              <el-dropdown size='mini' :show-timeout="100" :hide-timeout="100" @command='changeTaskStatus'>
                <div>
                  <span class="el-dropdown-link">任务状态 {{currentTaskStatus}}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown" divided>
                  <el-dropdown-item icon="el-icon-question" command='panding'>进行中</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-success" command='done'>已完成</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-error" command='expired'>已超时</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </span>
        </div>
        <div id='div-mainArea-editing-title'>
          <el-input type="text" placeholder="请输入内容" maxlength='8' show-word-limit v-model="data_task_current.task_title"
            @change="isUnsaved = true" @input="inputLimit('title')"></el-input>
        </div>
        <div id='div-mainArea-editing-description'>
          <el-input type="textarea" rows='10' placeholder="请输入内容" maxlength='1000' show-word-limit
            v-model="data_task_current.task_description" resize='none' @change="isUnsaved = true"
            @input="inputLimit('description')"></el-input>
        </div>
        <div id='div-mainArea-editing-times'>
          <div id='div-mainArea-editing-times-startTime'>
            <span> 任务开始时间 </span>
            <span> {{dataformat_startTime[0]}} {{dataformat_startTime[1]}}</span>
          </div>
          <div id='div-mainArea-editing-times-endTime'>
            <span> 预计结束时间 </span>
            <el-date-picker type="date" placeholder="选择日期" align="right" value-format='yyyyMMdd' size='mini'
              :clearable='false' v-model='data_task_current.task_endTime[0]' @change="isUnsaved = true"
              :picker-options='datapicker_options'>
            </el-date-picker>
            <el-time-picker placeholder="选择时间" value-format='HHmmss' size='mini' :clearable='false'
              v-model='data_task_current.task_endTime[1]' @change="isUnsaved = true" @focus='setTimepickerOptions()'
              :picker-options='timepicker_options' :disabled='data_task_current.task_endTime[0]==null'>
            </el-time-picker>
          </div>
        </div>
        <div id='div-mainArea-editing-options'>
          <el-button type="success" icon="el-icon-check" round size='mini' @click='saveItem'>保存项目</el-button>
          <el-button type="danger" icon="el-icon-delete" round size='mini' @click='deleteItem'>删除项目</el-button>
        </div>
      </div>

      <div id='div-mainArea-empty' v-show='!editingIndex>=0'>
        <i class='el-icon-cold-drink'></i><br>
        <span> 选择或创建一个项目来编辑 </span>
      </div>
    </div>
  </div>
</template>

<script>
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('ABCDEFGHIJ', 20);
export default {
  name: 'page-task',
  data () {
    return {
      data_task_all: [],
      data_task_list: [],
      data_task_current: { task_id: '', task_title: '', task_description: '', task_startTime: [], task_endTime: [], task_status: 'unsaved' },
      currentTaskStatus: '',
      isDataEmpty: true,
      sortType: '全部',
      isTaskSelectedAll: false,
      isCreating: false,
      isUnsaved: false,
      editingIndex: -1,
      key: 0,
      dataformat_startTime: ['', ''],
      datapicker_options: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      timepicker_options: {}
    }
  },
  methods: {
    showNotification (title, message, type, duration) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration
      });
    },
    changeSortType (command) {
      if (command == 'all') {
        this.sortType = '全部';
        this.data_task_list = this.data_task_all;
      }
      else if (command == 'panding') {
        this.sortType = '进行中';
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'panding');
      }
      else if (command == 'done') {
        this.sortType = '已完成';
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'done');
      }
      else if (command == 'expired') {
        this.sortType = '已超时';
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'expired');
      }
      if (this.data_task_list.length == 0) {
        this.isDataEmpty = true;
      }
      else {
        this.isDataEmpty = false;
      }
    },
    taskListSelectAll () {
      if (this.isDataEmpty) {
        this.showNotification('操作失败', '当前列表没有待选中项目', 'error', 3000);
      }
      else {
        let elements = document.getElementsByClassName('div-list-exist-data-item-checkbox');
        for (let element of elements) {
          element.checked = !this.isTaskSelectedAll;
        }
        this.isTaskSelectedAll = !this.isTaskSelectedAll;
        if (this.isTaskSelectedAll) {
          document.getElementsByClassName('el-icon-turn-off')[0].className = 'el-icon-open';
        }
        else {
          document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
        }
      }
    },
    taskListSelectEach () {
      let elements = document.getElementsByClassName('div-list-exist-data-item-checkbox');
      for (let index in elements) {
        if (!elements[index].checked) {
          break;
        }
        if (index == elements.length - 1) {
          if (document.getElementsByClassName('el-icon-turn-off').length > 0) {
            document.getElementsByClassName('el-icon-turn-off')[0].className = 'el-icon-open';
          }
          return;
        }
      }
      if (document.getElementsByClassName('el-icon-open').length > 0) {
        document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
      }
    },
    taskListCheckSelected () {
      let elements = document.getElementsByClassName('div-list-exist-data-item-checkbox');
      if ([...elements].filter(element => element.checked).length == 0) {
        this.showNotification('操作失败', '未选中任何项目', 'error', 3000);
      }
      else {
        for (let index = 0; index < elements.length; index++) {
          if (elements[index].checked) {
            for (let i = 0; i < this.data_task_all.length; i++) {
              if (this.data_task_all[i].task_id == this.data_task_list[index].task_id) {
                this.data_task_all[i].task_status = 'done';
                break;
              }
            }
          }
        }
        for (let element of elements) {
          element.checked = false;
        }
        this.showNotification('操作成功', '已将选中的项目标记为已完成', 'success', 3000);
        this.taskListRefreshSilent();
        if (this.isTaskSelectedAll) {
          document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
        }
      }
    },
    taskListRemoveSelected () {
      let elements = document.getElementsByClassName('div-list-exist-data-item-checkbox');
      if ([...elements].filter(element => element.checked).length == 0) {
        this.showNotification('操作失败', '未选中任何项目', 'error', 3000);
      }
      else {
        this.$confirm('此操作将永久删除项目, 是否继续?', '提示', {
          confirmButtonText: '确定删除',
          cancelButtonText: '取消删除',
          type: 'warning'
        }).then(() => {
          for (let index = elements.length - 1; index >= 0; index--) {
            if (elements[index].checked) {
              for (let i = 0; i < this.data_task_all.length; i++) {
                if (this.data_task_all[i].task_id == this.data_task_list[index].task_id) {
                  this.data_task_all.splice(i, 1);
                  break;
                }
              }
            }
          }
          for (let element of elements) {
            element.checked = false;
          }
          this.showNotification('操作成功', '已将选中的项目删除', 'success', 3000);
          this.taskListRefreshSilent();
          if (this.isTaskSelectedAll) {
            document.getElementsByClassName('el-icon-open')[0].className = 'el-icon-turn-off';
          }
        }).catch(() => {
        });
      }
    },
    taskListNew () {
      this.isCreating = true;
      this.data_task_current.task_startTime[0] = this.getCurrentTimeString()[0];
      this.data_task_current.task_startTime[1] = this.getCurrentTimeString()[1];
      this.dataformat_startTime[0] = this.data_task_current.task_startTime[0].substring(0, 4) + '-' + this.data_task_current.task_startTime[0].substring(4, 6) + '-' + this.data_task_current.task_startTime[0].substring(6, 8);
      this.dataformat_startTime[1] = this.data_task_current.task_startTime[1].substring(0, 2) + ':' + this.data_task_current.task_startTime[1].substring(2, 4) + ':' + this.data_task_current.task_startTime[1].substring(4, 6);
      this.data_task_current.task_status = 'panding';
      this.showItem(this.data_task_list.length);
    },
    taskListRefreshSilent () {
      this.writeToStorage()
      this.readFromStorage();
      if (this.sortType == '全部') {
        this.data_task_list = this.data_task_all;
      }
      else if (this.sortType == '进行中') {
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'panding');
      }
      else if (this.sortType == '已完成') {
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'done');
      }
      else if (this.sortType == '已超时') {
        this.data_task_list = this.data_task_all.filter(elements => elements.task_status == 'expired');
      }
      if (this.data_task_list.length > 0) {
        this.isDataEmpty = false;
        this.key++;
      }
      else {
        this.isDataEmpty = true;
      }
    },
    taskListRefresh () {
      this.taskListRefreshSilent();
      this.showNotification('操作成功', '已刷新任务列表', 'success', 3000);
    },
    inputLimit (place) {
      if (place === 'title') {
        if (this.data_task_current.task_title.length >= 8) {
          this.showNotification('注意', '标题文本不能超过8个字符', 'warning', 1000);
        }
      }
      else if (place === 'description') {
        if (this.data_task_current.task_description.length >= 1000) {
          this.showNotification('注意', '正文文本不能超过1000个字符', 'warning', 1000);
        }
      }
    },
    setTimepickerOptions () {
      this.timepicker_options = {
        selectableRange: (this.data_task_current.task_endTime[0] == this.getCurrentTimeString()[0] ?
          this.getCurrentTimeString()[1].substring(0, 2) + ':' + this.getCurrentTimeString()[1].substring(2, 4) + ':' + this.getCurrentTimeString()[1].substring(4, 6) : '00:00:00') + ' - 23:59:59'
      }
    },
    changeTaskStatus (command) {
      if (command == 'panding') {
        this.data_task_current.task_status = 'panding';
        this.currentTaskStatus = '进行中';
      }
      else if (command == 'done') {
        this.data_task_current.task_status = 'done';
        this.currentTaskStatus = '已完成';
      }
      else if (command == 'expired') {
        this.data_task_current.task_status = 'expired';
        this.currentTaskStatus = '已超时';
      }
      this.isUnsaved = true;
    },
    showItem (index) {
      if (!this.isCreating) {
        this.data_task_current.task_id = this.data_task_list[index].task_id;
        this.data_task_current.task_title = this.data_task_list[index].task_title;
        this.data_task_current.task_description = this.data_task_list[index].task_description;
        this.data_task_current.task_startTime = this.data_task_list[index].task_startTime;
        this.data_task_current.task_endTime = this.data_task_list[index].task_endTime;
        this.data_task_current.task_status = this.data_task_list[index].task_status;
        if (this.data_task_current.task_status == 'panding') {
          this.currentTaskStatus = '进行中';
        }
        else if (this.data_task_current.task_status == 'done') {
          this.currentTaskStatus = '已完成';
        }
        else if (this.data_task_current.task_status == 'expired') {
          this.currentTaskStatus = '已超时';
        }
        this.dataformat_startTime[0] = this.data_task_current.task_startTime[0].substring(0, 4) + '-' + this.data_task_current.task_startTime[0].substring(4, 6) + '-' + this.data_task_current.task_startTime[0].substring(6, 8);
        this.dataformat_startTime[1] = this.data_task_current.task_startTime[1].substring(0, 2) + ':' + this.data_task_current.task_startTime[1].substring(2, 4) + ':' + this.data_task_current.task_startTime[1].substring(4, 6);
      }
      this.editingIndex = index;
    },
    closeItem () {
      if (this.isUnsaved) {
        this.$confirm('自上次保存后的未保存修改将会丢失, 是否继续? <br> 点击关闭以取消返回', '提示', {
          confirmButtonText: '保存并返回',
          cancelButtonText: '不保存并返回',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          distinguishCancelAndClose: true,
        }).then(() => {
          this.saveItem();
          this.isCreating = false;
          this.isUnsaved = false;
          this.editingIndex = -1;
          this.data_task_current = { task_id: '', task_title: '', task_description: '', task_startTime: [], task_endTime: [], task_status: '' };
          this.dataformat_startTime = ['', ''];
        }).catch((action) => {
          if (action == 'cancel') {
            this.isCreating = false;
            this.isUnsaved = false;
            this.editingIndex = -1;
            this.data_task_current = { task_id: '', task_title: '', task_description: '', task_startTime: [], task_endTime: [], task_status: '' };
            this.dataformat_startTime = ['', ''];
          }
        });
      }
      else {
        this.isCreating = false;
        this.editingIndex = -1;
        this.data_task_current = { task_id: '', task_title: '', task_description: '', task_startTime: [], task_endTime: [], task_status: '' };
        this.dataformat_startTime = ['', ''];
      }
    },
    saveItem () {
      if (this.data_task_current.task_title.length <= 0 || !this.data_task_current.task_endTime[0] || !this.data_task_current.task_endTime[1]) {
        this.showNotification('操作失败', '请至少输入任务标题与结束时间', 'error', 3000);
      }
      else {
        if (this.isCreating) {
          this.data_task_current.task_id = nanoid();
          this.data_task_current.task_status = 'panding';
          this.data_task_all.push(this.data_task_current);
        }
        else {
          for (let index = 0; index < this.data_task_all.length; index++) {
            if (this.data_task_all[index].task_id == this.data_task_current.task_id) {
              this.data_task_list[this.editingIndex] = this.data_task_current;
              this.data_task_all[index] = this.data_task_current;
              break;
            }
          }
        }
        this.showNotification('操作成功', '保存成功', 'success', 3000);
        this.isCreating = false;
        this.isUnsaved = false;
        this.taskListRefreshSilent();
      }
    },
    deleteItem () {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(() => {
        this.showNotification('操作成功', '已删除项目', 'success', 3000);
        this.data_task_list.splice(this.editingIndex, 1);
        for (let index = 0; index < this.data_task_all.length; index++) {
          if (this.data_task_all[index].task_id == this.data_task_current.task_id) {
            this.data_task_all.splice(index, 1);
            break;
          }
        }
        this.editingIndex = -1;
        this.isUnsaved = false;
        this.data_task_current = { task_id: '', task_title: '', task_description: '', task_startTime: [], task_endTime: [], task_status: '' };
        this.dataformat_startTime = ['', ''];
        this.taskListRefreshSilent();
      }).catch(() => {
      });
    },
    writeToStorage () {
      localStorage.setItem('data_task_all', JSON.stringify(this.data_task_all));
      let lastUpdateClock = new Date();
      let lastUpdate = lastUpdateClock.getFullYear().toString();
      lastUpdate += (lastUpdateClock.getMonth() + 1) < 10 ? '0' + (lastUpdateClock.getMonth() + 1) : lastUpdateClock.getMonth() + 1;
      lastUpdate += lastUpdateClock.getDate() < 10 ? '0' + lastUpdateClock.getDate() : lastUpdateClock.getDate();
      lastUpdate += lastUpdateClock.getHours() < 10 ? '0' + lastUpdateClock.getHours().toString() : lastUpdateClock.getHours()
      lastUpdate += lastUpdateClock.getMinutes() < 10 ? '0' + lastUpdateClock.getMinutes() : lastUpdateClock.getMinutes();
      lastUpdate += lastUpdateClock.getSeconds() < 10 ? '0' + lastUpdateClock.getSeconds() : lastUpdateClock.getSeconds();
      localStorage.setItem('time_last_updated', lastUpdate);
    },
    readFromStorage () {
      if (localStorage.getItem('data_task_all') && localStorage.getItem('data_task_all').length > 2) {
        this.data_task_all = JSON.parse(localStorage.getItem('data_task_all'));
        this.isDataEmpty = false;
      }
      else {
        this.data_task_list = [];
        this.isDataEmpty = true;
      }
    },
    getCurrentTimeString () {
      let timeStamp = new Date();
      let currentTimeDate = timeStamp.getFullYear().toString();
      currentTimeDate += (timeStamp.getMonth() + 1) < 10 ? '0' + (timeStamp.getMonth() + 1) : timeStamp.getMonth() + 1;
      currentTimeDate += timeStamp.getDate() < 10 ? '0' + timeStamp.getDate() : timeStamp.getDate();
      let currentTimeClock = timeStamp.getHours() < 10 ? '0' + timeStamp.getHours().toString() : timeStamp.getHours().toString();
      currentTimeClock += timeStamp.getMinutes() < 10 ? '0' + timeStamp.getMinutes() : timeStamp.getMinutes();
      currentTimeClock += timeStamp.getSeconds() < 10 ? '0' + timeStamp.getSeconds() : timeStamp.getSeconds();
      return [currentTimeDate, currentTimeClock];
    }
  },
  mounted () {
    this.readFromStorage();
    this.data_task_list = this.data_task_all;

    setInterval(() => {
      let isAnyExpired = false;

      let currentTime = new Date();
      let timeString = currentTime.getFullYear().toString();
      timeString += (currentTime.getMonth() + 1) < 10 ? '0' + (currentTime.getMonth() + 1) : currentTime.getMonth() + 1;
      timeString += currentTime.getDate() < 10 ? '0' + currentTime.getDate() : currentTime.getDate();
      timeString += currentTime.getHours() < 10 ? '0' + currentTime.getHours().toString() : currentTime.getHours();
      timeString += currentTime.getMinutes() < 10 ? '0' + currentTime.getMinutes() : currentTime.getMinutes();
      timeString += currentTime.getSeconds() < 10 ? '0' + currentTime.getSeconds() : currentTime.getSeconds();

      for (let element of this.data_task_all) {
        if (timeString >= element.task_endTime[0] + element.task_endTime[1]) {
          if (element.task_status == 'panding') {
            element.task_status = 'expired';
            isAnyExpired = true;
          }
        }
      }

      if (isAnyExpired) {
        this.showNotification('注意', '有一个或多个项目刚刚超时', 'warning', 50000, 'buttom-left');
        this.taskListRefreshSilent();
        isAnyExpired = false;
      }
    }, 60000);
  }
}
</script>

<style scoped>
#page-task #div-list {
  position: absolute;
  background-color: rgb(62, 249, 205);
  border-right: 1px solid darkorange;
  width: 150px;
  height: 100%;
}
#page-task #div-list-manage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  text-align: center;
}
#page-task #div-list-manage div {
  cursor: pointer;
}
#page-task #div-list-main-sort {
  width: 140px;
  margin: 5px 5px 0 5px;
  text-align: center;
}
#page-task #div-list-main-sort div {
  cursor: pointer;
  font-size: 15px;
}
#page-task #div-list-main #div-list-empty {
  text-align: center;
}
#page-task #div-list-empty i {
  margin: 50px 0 0 0;
  font-size: 100px;
}
#page-task #div-list-empty div {
  font-size: 15px;
}
#page-task #div-list-cover {
  position: fixed;
  left: 0;
  top: 30px;
  height: 100%;
  width: 150px;
  z-index: 10;
  cursor: pointer;
  background-color: rgba(189, 185, 185, 0.626);
}
#page-task #div-list-exist-data-list {
  margin: 10px 10px 0 10px;
}
#page-task .div-list-exist-data-item {
  margin: 5px 0 5px 0;
}
#page-task .div-list-exist-data-item-checkbox {
  position: relative;
  top: 3px;
}
#page-task .div-list-exist-data-item span {
  font-size: 10px;
  cursor: pointer;
}
#page-task .div-list-exist-data-item i {
  position: relative;
  top: 5px;
  float: right;
}
#page-task #div-mainArea {
  margin: 0 0 0 200px;
}
#page-task #div-mainArea-empty {
  text-align: center;
}
#page-task #div-mainArea-empty i {
  margin: 100px 0 30px 0;
  font-size: 100px;
}
#page-task #div-mainArea-editing-top-back {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
#page-task #div-mainArea-editing-top-status {
  float: right;
  font-size: 20px;
  margin: 0 10px 0 0;
}
#page-task #div-mainArea-editing-top-status span {
  font-size: 15px;
  margin: 0 0 0 5px;
}
#page-task #div-mainArea-editing {
  font-size: 15px;
}
#page-task #div-mainArea-editing-title {
  margin: 5px 0 5px 0;
}
#page-task #div-mainArea-editing-description {
  margin: 0 0 5px 0;
}
#page-task #div-mainArea-editing-times-startTime {
  margin: 10px 0 5px 0;
}
#page-task #div-mainArea-editing .el-date-editor,
#page-task #div-mainArea-editing .el-time-editor {
  width: 125px;
}
#page-task #div-mainArea-editing-options {
  margin: 20px 0 0 0;
  text-align: center;
}
#page-task #div-mainArea-editing-options .el-button {
  margin: 0 50px 0 0;
}
</style>
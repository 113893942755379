<template>
  <div>
    <keep-alive>
      <router-view>
      </router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'window-board',
  mounted () {
    this.$router.push({
      name: 'PageIndex',
    })
    this.$bus.$on('router_board_task', () => {
      this.$router.push({
        name: 'PageTask',
      })
    })
    this.$bus.$on('router_board_counter', () => {
      this.$router.push({
        name: 'PageCounter',
      })
    })
  }
}
</script>

<style scoped>
</style>
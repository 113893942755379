<template>
  <div id='app'>
    <window-banner id='window-banner'></window-banner>
    <window-menu id='window-menu'></window-menu>
    <window-board id='window-board'></window-board>
    <el-dialog id='window-logbox' title="登录" :visible.sync='ifLogboxShowing' :show-close='false'
      :close-on-click-modal='false' :close-on-press-escape='false'>
      <window-logbox></window-logbox>
    </el-dialog>
    <div id='window-cover' v-show='ifMenuShowing||ifLogboxShowing' @click='closeMenu'></div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie'
import * as server from './server.js';

import WindowBanner from './components/MainFrame/WindowBanner.vue'
import WindowBoard from './components/MainFrame/WindowBoard.vue'
import WindowLogbox from './components/MainFrame/WindowLogbox.vue'
import WindowMenu from './components/MainFrame/WindowMenu.vue'

export default {
  name: 'App',
  components: {
    WindowBanner,
    WindowMenu,
    WindowBoard,
    WindowLogbox,
  },
  data () {
    return {
      ifMenuShowing: false,
      ifLogboxShowing: false,
    }
  },
  methods: {
    showNotification (title, message, type, duration, position = 'top-right') {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration,
        position: position,
      });
    },
    openMenu () {
      document.getElementById('window-menu').style.display = 'block';
      document.getElementById('window-menu').className = 'animate__animated animate__slideInLeft animate__faster';
      this.ifMenuShowing = true;
    },
    closeMenu () {
      document.getElementById('window-menu').className = 'animate__animated animate__slideOutLeft animate__faster';
      this.ifMenuShowing = false;
    },
    openLogin () {
      this.ifLogboxShowing = true;
    },
    closeLogin () {
      this.ifLogboxShowing = false;
    },
    changeLogbox (target) {
      console.log(document.getElementById('window-logbox'));
      if (target == 'login') {
        document.getElementById('window-logbox').title = '登录';
      }
      else if (target == 'register') {
        document.getElementById('window-logbox').title = '注册';
      }
    },
    autoLogin () {
      if (Cookies.get('loginCookie') != null) {
        const loadingInstance = this.$loading({
          lock: true,
          text: '登录中'
        });
        axios({
          method: 'post',
          url: server.default.autoLoginPath,
          data: new URLSearchParams({
            'cookie': Cookies.get('loginCookie')
          })
        }).then((response) => {
          loadingInstance.close();
          if (response.status != 200) {
            this.$alert('无法连接到服务器, 请稍后重试', '消息');
          }
          else {
            if (response.data == 'ERR_LOGIN_NOCOOKIE') {
              this.showNotification('登录超时', ' 请重新使用用户名登录', 'error', 3000);
              this.openLogin();
            }
            else if (response.data.status == 'SUC_LOGIN') {
              this.showNotification('登录成功', '欢迎回来 ' + response.data.username, 'success', 3000);
              this.$bus.$emit('showUsername', response.data.username);
            }
          }
        })
      }
      else {
        this.openLogin();
      }
    }
  },
  mounted () {
    this.$bus.$on('openMenu', this.openMenu);
    this.$bus.$on('closeMenu', this.closeMenu);
    this.$bus.$on('openLogin', this.openLogin);
    this.$bus.$on('closeLogin', this.closeLogin);
    this.autoLogin();
  },
  beforeDestroy () {
    this.$bus.$off('openMenu');
    this.$bus.$off('closeMenu');
    this.$bus.$off('openLogin');
    this.$bus.$off('closeLogin');
  }
}
</script>

<style scoped>
#window-banner {
  height: 30px;
  width: 100%;
  z-index: 3;
}
#window-menu {
  position: fixed;
  height: 100%;
  width: 150px;
  float: left;
  z-index: 5;
  display: none;
}
#window-board {
  position: fixed;
  top: 30px;
  height: 100%;
  width: 100%;
  z-index: 3;
}
#window-logbox {
  z-index: 999;
}
#window-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 185, 185, 0.626);
  z-index: 4;
}
</style>
